import React, { lazy, Suspense } from "react";
import { RouteObject } from "helpers/tixxt-router";
import ChannelsPage from "./ChannelsPage";
import ChannelPage from "components/channels/ChannelPage";
import ShowWidgetPage from "components/channels/widgets/ShowWidgetPage";

const ChannelNewPage = lazy(() => import("components/channels/ChannelNewPage"));
const ChannelEditPage = lazy(
  () => import("components/channels/ChannelEditPage"),
);
const EditLayoutPage = lazy(
  () => import("components/channels/layout/EditLayoutPage"),
);
const EditDesignPage = lazy(
  () => import("components/channels/design/EditDesignPage"),
);
const WidgetsPage = lazy(
  () => import("components/channels/widgets/WidgetsPage"),
);
const NewWidgetPage = lazy(
  () => import("components/channels/widgets/NewWidgetPage"),
);
const EditWidgetPage = lazy(
  () => import("components/channels/widgets/EditWidgetPage"),
);

const channelRoutes: RouteObject[] = [
  { path: "/channels", element: <ChannelsPage /> },
  {
    path: "/channels/new",
    element: (
      <Suspense fallback={"Loading..."}>
        <ChannelNewPage />
      </Suspense>
    ),
  },
  {
    path: "/channels/:channelSlug/edit",
    element: (
      <Suspense fallback={"Loading..."}>
        <ChannelEditPage />
      </Suspense>
    ),
  },
  {
    path: "/channels/:channelSlug",
    element: <ChannelPage />,
  },
  {
    path: "/channels/:channelSlug/layout/edit",
    element: (
      <Suspense fallback={"Loading..."}>
        <EditLayoutPage />
      </Suspense>
    ),
  },
  {
    path: "/channels/:channelSlug/design/edit",
    element: (
      <Suspense fallback={"Loading..."}>
        <EditDesignPage />
      </Suspense>
    ),
  },
  {
    path: "/channels/:channelSlug/widgets",
    element: (
      <Suspense fallback={"Loading..."}>
        <WidgetsPage />
      </Suspense>
    ),
  },
  {
    path: "/channels/:channelSlug/widgets/new",
    element: (
      <Suspense fallback={"Loading..."}>
        <NewWidgetPage />
      </Suspense>
    ),
  },
  {
    path: "/channels/:channelSlug/widgets/:widgetId/edit",
    element: (
      <Suspense fallback={"Loading..."}>
        <EditWidgetPage />
      </Suspense>
    ),
  },
  {
    path: "/channels/:channelSlug/widgets/:widgetId",
    element: (
      <Suspense fallback={"Loading..."}>
        <ShowWidgetPage />
      </Suspense>
    ),
  },
];

export default channelRoutes;
