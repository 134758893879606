import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleChevronLeft as faSolidCircleChevronLeft,
  faCircleChevronRight as faSolidCircleChevronRight,
} from "@fortawesome/pro-solid-svg-icons";
import { map } from "lodash";
import classNames from "classnames";

type ItemCarouselArgs = {
  children: React.ReactNode[] | React.ReactNode;
  visibleItemsCount: number;
  containerWidth: number;
  containerHeight: string;
  totalItems: number;
};

export default function ItemCarousel({
  children,
  visibleItemsCount,
  containerWidth,
  totalItems,
  containerHeight,
}: ItemCarouselArgs) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const maxIndex = Math.max(0, Math.ceil(totalItems / visibleItemsCount) - 1);

  const handlePrev = () => {
    setCurrentIndex((prev) => Math.max(0, prev - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prev) => Math.min(maxIndex, prev + 1));
  };

  const totalGap = 8 * visibleItemsCount; // gap-2 is 8px
  const itemPercent = 100 / visibleItemsCount;
  const gapPerItem = (8 / visibleItemsCount) * (visibleItemsCount - 1);
  const translateAmount =
    currentIndex * (containerWidth + (totalGap - 8 * (visibleItemsCount - 1)));

  return (
    <div style={{ width: containerWidth }}>
      <div className={classNames("carousel__container", containerHeight)}>
        {currentIndex > 0 && (
          <button onClick={handlePrev} className="carousel__button--prev">
            <FontAwesomeIcon icon={faSolidCircleChevronLeft} size="2xl" />
          </button>
        )}
        {currentIndex < maxIndex && (
          <button onClick={handleNext} className="carousel__button--next">
            <FontAwesomeIcon icon={faSolidCircleChevronRight} size="2xl" />
          </button>
        )}

        <div
          className={classNames(
            "carousel__content overflow-hidden",
            containerHeight,
          )}
        >
          <div
            className="flex gap-2 transition-transform duration-1000 max-h-[21rem]"
            style={{
              transform: `translateX(-${translateAmount}px)`,
            }}
          >
            {map(children as React.ReactNode[], (child, index) => (
              <div
                key={index}
                className={classNames(
                  "flex flex-col shrink-0",
                  containerHeight,
                )}
                style={{
                  width: `calc(${itemPercent}% - ${gapPerItem}px)`,
                }}
              >
                {child}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
